import { Controller } from "stimulus"

export default class extends Controller {

  get remoteElement() {
    return document.getElementById(this.data.get("elementId"))
  }

  toggle(event) {
    event.stopPropagation()
    this.transitionController.toggle()
  }

  get transitionController() {
    return this.application.getControllerForElementAndIdentifier(this.remoteElement, "transition")
  }

}
