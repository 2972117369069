import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "state", "animation" ]

  get isOn() {
    return (this.data.get("isOn") === "true")
  }

  set isOn(value) {
    const turnOn = (value === true) 
    if (turnOn === this.isOn) return
    turnOn ? this.enter() : this.leave()
    this.data.set("isOn", turnOn)
  }

  toggle(event) {
    this.isOn = !this.isOn
  }

  unfocus(event) {
    this.element.contains(event.target) ? null : this.isOn = false
  }

  dismiss(event) {
    this.isOn = false
  }

  enter() {
    this.stateTargets.forEach((target) => this.toggleState(target))
    this.animationTargets.forEach((element) => this.enterElement(element))
  }

  async leave() {
    this.animationTargets.forEach((element) => this.leaveElement(element))
    await this.afterLongestTransition()
    this.stateTargets.forEach((target) => this.toggleState(target))
  }

  toggleState(element) {
    element.classList.toggle(...this.transitionClasses(element, 'transitionState'))
  }

  async enterElement(element) {
    element.classList.add(...this.transitionClasses(element, 'transitionEnter'))
    element.classList.add(...this.transitionClasses(element, 'transitionEnterFrom'))

    await this.nextFrame()

    element.classList.remove(...this.transitionClasses(element, 'transitionEnterFrom'))
    element.classList.add(...this.transitionClasses(element, 'transitionEnterTo'))

    await this.afterTransition(element)

    element.classList.remove(...this.transitionClasses(element, 'transitionEnterTo'))
    element.classList.remove(...this.transitionClasses(element, 'transitionEnter'))

    this.isOn = true
  }

  async leaveElement(element) {
    element.classList.add(...this.transitionClasses(element, 'transitionLeave'))
    element.classList.add(...this.transitionClasses(element, 'transitionLeaveFrom'))

    await this.nextFrame()

    element.classList.remove(...this.transitionClasses(element, 'transitionLeaveFrom'))
    element.classList.add(...this.transitionClasses(element, 'transitionLeaveTo'))

    await this.afterTransition(element)

    element.classList.remove(...this.transitionClasses(element, 'transitionLeaveTo'))
    element.classList.remove(...this.transitionClasses(element, 'transitionLeave'))


    this.isOn = false
  }

  transitionClasses(element, transition) {
    return element.dataset.hasOwnProperty(transition) ? element.dataset[transition].split(" ") : []
  }

  nextFrame() {
    return new Promise(resolve => {
      requestAnimationFrame(() => {
        requestAnimationFrame(resolve)
      })
    })
  }

  afterTransition(element) {
    const duration = Number(getComputedStyle(element).transitionDuration.split(",")[0].replace("s", "")) * 1000 
    return new Promise(resolve => {
      setTimeout(() => { resolve() }, duration)
    })
  }

  afterLongestTransition() {
    const duration = Math.max(...this.animationTargets.map(target => {
      return Number(getComputedStyle(target).transitionDuration.split(",")[0].replace("s", "")) * 1000
    }))
    return new Promise(resolve => {
      setTimeout(() => { resolve() }, duration)
    })
  }
}
